import { Flex, Box, Tag, Heading, Image, Container } from "@chakra-ui/react";
import parse from "html-react-parser";
import useSite from "../../../hooks/use-site";
import { wpStyles } from "../../../theme/wp-styles";
const ProductTitle = () => {
  const {
    planData: { coverageStartDate, title, logo, description, productColor },
  } = useSite();
  return (
    <Box as="header">
      <Box bg={productColor}>
        <Container py="4">
          <Flex gap={2} fontSize="md" color="white">
            <Box fontWeight={"bold"}>Starting Coverage</Box>
            <Tag
              size="sm"
              color="white"
              bg="black"
              variant="solid"
              colorScheme="whiteAlpha"
              fontWeight="900"
              py={0}
              minH={0}
              minW={0}
              textTransform="uppercase"
            >
              {` ${coverageStartDate}`}
            </Tag>
          </Flex>
          <Heading
            as={"h1"}
            color="white"
            mb="0"
            fontSize={wpStyles.fontSize.xl}
          >
            {title}
          </Heading>
        </Container>
      </Box>
      <Container my={8}>
        <Box
          fontSize={"md"}
          sx={{
            img: {
              my: 4,
              maxW: "250px",
            },
          }}
        >
          {logo && (
            <img
              width={`${logo.width}px`}
              height={`${logo.height}px`}
              src={logo.url}
              alt={logo.alt}
            />
          )}
          {parse(description)}
        </Box>
      </Container>
    </Box>
  );
};

export default ProductTitle;
